@font-face {
  font-family: tahoma;
  src: url("/public/tahoma.ttf");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  font-family: "tahoma";
  font-size: 20px;
  background-color: black;
}
main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  /* min-height: 100%; */
  width: 95%;
  height: 90%;
  margin: 0;
  max-width: 600px;
  position: relative;
}
.App {
  width: 100dvw;
  height: 100dvh;
  margin: 0;
  color: white;
  display: flex;
  
  align-items: center;
  flex-direction: column;
 
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.41);
  border-radius: 50px;
}
::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}
