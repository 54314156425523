@font-face {
  font-family: tahoma;
  src: url("/public/tahoma.ttf");
}


.panel {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
#messages {
  overflow-y: auto;
  padding: 0;
  
}
.msg {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  font-size: .7em;
  gap: 5px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-end;
  transition: 1s;
  animation: msg_appear 1s forwards;
}
@keyframes msg_appear {
  from {
    opacity: 0;
    
  }
  to {
    opacity: 1;
    
  }
  
}
.msg.i {
  justify-content: flex-end;
  flex-direction: row;
}

.msg.i .msg-txt {
  color: white;
  background: #474747;
}
.username-txt {
  color: black;
  background-color: #cf6969;
  width: 25px;
  padding: 3px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  font-size: 1.2em;
}
.msg-txt {
  background: white;
  max-width: 50%;
  padding: 10px;
  border-radius: 15px;
  filter: drop-shadow(1px 4px 3px #000000);
  overflow-wrap: break-word;
  color: #474747;
  letter-spacing: 0.6px;
}

.msg-inp-form {
  width: 100%;
  display: flex;
  align-items: flex-end;

  background-color: black;
  position: absolute;
  bottom: 10px;
}
.msg-inp-form textarea {
  font-family: "tahoma";
  font-size: 0.7em;
  padding: 10px;
  width: 80%;
  border-radius: 10px;
  line-height: 20px;
  outline: none;
  background-color: black;
  letter-spacing: 0.6px;
  color: white;
}

.msg-inp-form button {
  font-size: medium;
  border-radius: 10px;
  width: 60px;
  height: 30px;
  background: transparent;
  color: white;
  cursor: pointer;
  border-color: darkseagreen;
  transition: 250ms ease-in-out;
}
#logoutBtn{
  border-color: red;
}
#logoutBtn:hover{
  background-color: red;
}
.msg-inp-form button:disabled{
  border-color: #474747;
  color: #474747;
  cursor: not-allowed;
}
/* Mobile */
@media (max-width: 700px) {
  .heading-text {
    font-size: 1em;
  }
}

@media (min-width: 701px) and (max-width: 1120px) {
  .heading-text {
    font-size: 1em;
  }
}
