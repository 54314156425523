#login-form input{
    display: block;
    font-size: 1.2em;
    margin: 10px 0;
    padding: 7px 5px;
    border-radius: 10px;
}
#login-form button{
    font-size: 1em;
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
}
fieldset{
    
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0;
}